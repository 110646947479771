import React, { useContext, useEffect } from "react";
import { KfAnchorLink, KfHeroUtility } from "@klickinc/kf-react-components";
import AppContext from "@src/context"
// Utility imports
import Seo from "@components/utility/SEO";

import "./styles.scss";

const CookiePolicy = () => {
	const ctx = useContext(AppContext);

	const toggleCookieBanner = () => {
		window?.OneTrust.ToggleInfoDisplay();
	}

	useEffect(() => {
		ctx.setPageClass('page--cookie-policy main');
	}, []);

	return (<>
		<section>
			<div className="container space-y-6 container--inner">
				<KfHeroUtility addedClass="heading-hero-blue heading">
					Cookie Policy
				</KfHeroUtility>
				<section className="space-y-6">
					<h2 className="heading">Cookies</h2>
					<p>
						A cookie is a small piece of data (text file) that a website — when visited by you — asks your browser to store on your device in order to remember information about you. Certain cookies are set by us and called first party cookies. Our first party cookies may collect information about your usage of our Site, such as time spent on the Site, pages visited, language preferences, and other traffic data.
					</p>
					<p>
						We also use third party cookies — which are cookies from a domain different than the domain of the website you are visiting — for our advertising and marketing efforts. The parties that set these third party cookies can recognize your computer or device both when it visits our Site and also when it visits certain other sites and/or apps. We do not control how these third parties use your information, nor do we retain access to the information collected by third party cookies. The information is controlled wholly by that third party and is subject to the third party's own privacy policy.
					</p>
					<p>
						You can control the way in which cookies are used by altering your browser settings. You may refuse to accept cookies by activating the setting on your browser that allows you to reject cookies. However, if you select such a setting, this may affect the functioning of our Site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you access or log on to our Site. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <KfAnchorLink url="https://www.aboutcookies.org" target="_blank">www.aboutcookies.org</KfAnchorLink> or <KfAnchorLink url="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</KfAnchorLink>.
					</p>
					<p>
						We use the information collected by these technologies for security purposes, to facilitate navigation, to personalize and improve your experience while using the Site, to improve and measure our advertising campaigns and to better reach users with relevant advertising both on our Site and on third party websites. We also gather statistical information about use of the Site in order to continually improve its design and functionality, understand how they are used and assist us with resolving questions regarding them. Do Not Track signals. Please note that our Site does not recognize or respond to any signal which your browser might transmit through the so&#8209;called 'Do Not Track' feature your browser might have. If you wish to disable cookies on our Site, you should not rely on any 'Do Not Track' feature your browser might have. For more information about Do Not Track signals, please <KfAnchorLink url="https://allaboutdnt.com" target="_blank">click here</KfAnchorLink>.
					</p>
				</section>

				<p>We use the following types of cookies:</p>

				<section className="space-y-6">
					<h2 className="heading">Strictly Necessary Cookies</h2>
					<p>
						These cookies are necessary for the website to function and cannot be switched off in our systems. You can set your browser to block or alert you about these cookies, but then some parts of the Site will not work.
					</p>
				</section>

				<section className="space-y-6">
					<h2 className="heading">Performance Cookies</h2>
					<p>
						These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our Site. They help us know which pages are the most and least popular and see how visitors move around the Site. If you do not allow these cookies, we will not know when you have visited our Site.
					</p>
				</section>
				<section className="space-y-6">
					<h2 className="heading">Targeting Cookies</h2>
					<p>
						These cookies may be set through our Site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant advertisements on other sites. If you do not allow these cookies, you will experience less targeted advertising.
					</p>
				</section>

				<section className="space-y-6 section-2">
					<h2 className="heading">Opting Out of Cookies</h2>
					<div>
						<p>
							Under the California Consumer Privacy Act (CCPA), a "sale" includes disclosing or making available personal information to a third party in exchange for monetary compensation or other benefits or value. While we do not disclose personal information to third parties in exchange for monetary compensation, we may disclose or make available certain categories of personal information to third parties in order to receive certain benefits or services, such as when we make browsing information available to third party ad companies (through third party tags on our Sites) in order to improve and measure our ad campaigns and reach users with more relevant ads and content. As defined by the CCPA, we may "sell" certain categories of personal information as follows:
						</p>
						<ul className="mt-2 bulleted-list">
							<li> Usage Data and Identifiers to third party advertising networks and data analytics providers; and</li>
							<li> Identifiers to third party marketing partners who may contact you about products and services you may be interested in</li>
						</ul>
					</div>

					<p>
						Users in California may opt out of the sale of their personal information by clicking this link <br className='inline md:hidden' />“<button onClick={toggleCookieBanner} className="cookieBtn" data-ot-ccpa-opt-out="link"><span className='underline'>Do Not Sell My Personal Information</span></button>”.
					</p>

					<p>
						Most browsers also allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up&#8209;to&#8209;date information about blocking and deleting cookies via these links:
					</p>

					<div className="pb-6">
						<ol className="list-decimal">
							<li className="break-words">
								<KfAnchorLink target="_blank" url="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/ <br className="md:hidden" /> answer/95647</KfAnchorLink> (Chrome)
							</li>
							<li>
								<KfAnchorLink target="_blank" url="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</KfAnchorLink> (Firefox)
							</li>
							<li>
								<KfAnchorLink target="_blank" url="https://help.opera.com/en/latest/security-and-privacy/">https://help.opera.com/en/latest/security-and-privacy/</KfAnchorLink> (Opera)
							</li>
							<li>
								<KfAnchorLink target="_blank" url="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</KfAnchorLink> (Internet Explorer)
							</li>
							<li>
								<KfAnchorLink target="_blank" url="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</KfAnchorLink> (Safari)
							</li>
							<li>
								<KfAnchorLink target="_blank" url="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</KfAnchorLink> (Edge)
							</li>
						</ol>
					</div>
				</section>
			</div>
		</section>
		<section className="relative">
			<div className="pink-bg">
			</div>
			<div className="container pt-6 pb-16 space-y-6 container--inner">
				<p>
					Blocking all cookies will have a negative impact upon the usability of many websites. If you block cookies, you will not be able to use all the features on our Site.
				</p>
				<section className="space-y-6">
					<h2 className="heading">Analytics</h2>
					<p>
						We use automated devices and applications, such as Google Analytics, to evaluate usage of our Site. We use these tools to help us improve the performance of our website and our user's experience on our website. These entities may use cookies and other tracking technologies, such as pixels or local storage objects (LSOs), to perform their services. To learn more about Google's privacy practices, please review the Google Privacy Policy at <KfAnchorLink url="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</KfAnchorLink>. You can also download the Google Analytics Opt-out Browser Add&#8209;on to prevent your data from being used by Google Analytics at <KfAnchorLink url="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</KfAnchorLink>.
					</p>
				</section>

				<section className="space-y-6">
					<h2 className="heading">Ad Network</h2>
					<p>
						We use network advertisers to serve advertisements on non&#8209;affiliated websites or other media (eg, social networking platforms). This enables us and these network advertisers to target advertisements to you for products and services in which you might be interested. Ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, pixels, LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you. These cookies and other technologies are governed by each entity's specific privacy policy, not this one. We may provide these advertisers with information, including personal information, about you.
					</p>
					<p>
						Users may opt out of many ad networks. For example, you may go to the Digital Advertising Alliance ("DAA") <KfAnchorLink url="https://optout.aboutads.info" target="_blank">Consumer Choice Page</KfAnchorLink> for information about opting out of interest&#8209;based advertising and their choices regarding having information used by <KfAnchorLink url="https://youradchoices.com/learn#zone-preface5-wrapper" target="_blank">DAA companies</KfAnchorLink>. You may also go to the Network Advertising Initiative ("NAI") <KfAnchorLink url="https://optout.networkadvertising.org" target="_blank">Consumer Opt&#8209;Out Page</KfAnchorLink> for information about opting out of interest&#8209;based advertising and their choices regarding having information used by <a href="https://thenai.org/about/members/" target='_blank' rel="noreferrer" className="underline">NAI members</a>.
					</p>
					<p>
						Opting out from one or more companies listed on the DAA <KfAnchorLink url="https://optout.aboutads.info" target="_blank">Consumer Choice Page</KfAnchorLink> or the NAI <KfAnchorLink url="https://optout.networkadvertising.org" target="_blank">Consumer Opt&#8209;Out Page</KfAnchorLink> will opt you out from those companies' delivery of interest&#8209;based content or ads to you, but it does not mean you will no longer receive any advertising through our website or on other websites. You may continue to receive advertisements, for example, based on the particular website that you are viewing (ie, contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA or NAI websites, your opt out may not be effective. Additional information is available on the DAA's website at <KfAnchorLink url="https://youradchoices.com/" target="_blank">www.aboutads.info</KfAnchorLink> or the NAI's website at <KfAnchorLink url="https://www.networkadvertising.org" target="_blank">www.networkadvertising.org</KfAnchorLink>.
					</p>
				</section>

				<section className="space-y-3 md:space-y-2">
					<h2 className="heading">Our Details</h2>
					<p>
						This website is owned and operated by PTC Therapeutics Inc. with its corporate headquarters at 500 Warren Corporate Center Drive, Warren, NJ 07059
					</p>
					<p>You can contact us:</p>
					<p>By telephone, at <KfAnchorLink url="tel:9082227000" target="_blank">1&#8209;908&#8209;222&#8209;7000</KfAnchorLink>; or</p>
					<p>By email, using <KfAnchorLink url="mailto:info@ptcbio.com" target="_blank">info@ptcbio.com</KfAnchorLink>.</p>
				</section>
			</div>
		</section>
	</>)
}

export default CookiePolicy;

export function Head() {
	return (
		<Seo title="Cookie Policy - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
